import { Stack, Text, Badge } from '@mantine/core';
import React, { ReactNode } from 'react';

const SectionHeader = ({
  badge,
  title,
  subtitle,
  align = 'center',
}: {
  badge?: string;
  title?: ReactNode;
  subtitle?: string;
  align?: 'left' | 'center';
}) => {
  return (
    <Stack gap="12px" align={align}>
      {badge && <Badge variant="default">{badge}</Badge>}
      {title && (
        <Text
          component="h2"
          variant="gradient"
          gradient={{ from: '#101828', to: '#667085', deg: 180 }}
          pb="6px"
          lh={{ base: '30px', md: '60px' }}
          fw="bolder"
          fz={{ base: '30px', md: '60px' }}
          ta={align}
          maw={800}
          style={{
            letterSpacing: '-1.2px',
          }}
        >
          {title}
        </Text>
      )}
      {subtitle && (
        <Text size="xl" c="shepherdGray.5" ta={align} maw={640} fw={400}>
          {subtitle}
        </Text>
      )}
    </Stack>
  );
};

export default SectionHeader;
