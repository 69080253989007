'use client';
import { Container, Text, SimpleGrid, Card, AspectRatio, Flex, Box } from '@mantine/core';
import React from 'react';
import SectionHeader from '../SectionHeader';

import decisions from '../../app/images/svgs/decisions.svg';

import priceCheck2 from '../../app/images/svgs/price_check_value_prop.svg';

import aiPowered from '../../app/images/svgs/aiPowered.svg';
import Image from 'next/image';

const VALUE_PROPS = [
  {
    image: decisions,
    title: 'Decisions in hours instead of weeks',
    subtitle: 'Eliminated administrative waste enables an average response time of just 12 hours',
  },
  {
    image: priceCheck2,
    title: 'Up to 25% premium savings',
    subtitle:
      'Automatic credits for customers who utilize leading technologies for safety and risk',
  },
  {
    image: aiPowered,
    title: 'AI-powered risk reduction',
    subtitle: 'Free software to solve common risk management pain points and reduce expenses',
  },
  {
    image: null,
    title: 'Industry-practice offerings',
    subtitle: 'Broad appetite supported by a multiple products that work for programs and projects',
  },
];

const ValueProps = () => {
  return (
    <Container size="xl" pos="relative">
      <Flex direction="column" gap={{ base: 24, md: 96 }}>
        <SectionHeader
          badge="Why Shepherd"
          title="Specialty underwriters powered by world-class tech"
          subtitle="Shepherd provides insurance capacity alongside risk management software, integrations to popular tools, and a curated app marketplace"
        />
        <SimpleGrid visibleFrom="md" cols={{ base: 1, md: 3 }}>
          {VALUE_PROPS.map(
            (item) =>
              item.image && (
                <Card padding="24px" radius="md" key={item.title} bg="shepherdGray.1">
                  <Card.Section>
                    <AspectRatio ratio={16 / 9}>
                      <Box pos="relative">
                        <Image
                          src={item.image}
                          alt={item.title}
                          fill
                          style={{ objectFit: 'cover' }}
                        />
                      </Box>
                    </AspectRatio>
                  </Card.Section>

                  <Text fw={600} size="lg" pt="md" c="shepherdGray.9">
                    {item.title}
                  </Text>

                  <Text c="shepherdGray.5">{item.subtitle}</Text>
                </Card>
              )
          )}
        </SimpleGrid>
      </Flex>
    </Container>
  );
};

export default ValueProps;
