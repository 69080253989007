import * as React from 'react';
import type { SVGProps } from 'react';
const SvgDone = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" {...props}>
    <path
      fill="#667085"
      d="M5.867 10.603 3.533 8.27a.656.656 0 0 0-.933 0 .656.656 0 0 0 0 .933l2.793 2.793c.26.26.68.26.94 0l7.067-7.06a.656.656 0 0 0 0-.933.656.656 0 0 0-.933 0z"
    />
  </svg>
);
export default SvgDone;
