'use client';
import {
  Text,
  Badge,
  Grid,
  Stack,
  SimpleGrid,
  AspectRatio,
  Container,
  Box,
  Flex,
} from '@mantine/core';
import React from 'react';
import ShepherdGlowButton from '../ShepherdGlowButton/ShepherdGlowButton';
import Image from 'next/image';
import procore from '../../app/images/procore2.png';
import samsara from '../../app/images/samsara.png';
import openspace from '../../app/images/openspace.png';
import autodesk from '../../app/images/autodesk.png';

const IMAGES = [
  {
    image: procore,
    title: 'Procore',
  },
  {
    image: openspace,
    title: 'OpenSpace',
  },
  {
    image: samsara,
    title: 'Samsara',
  },

  {
    image: autodesk,
    title: 'Autodesk',
  },
];

const RewardsSection = () => {
  return (
    <Container
      size="lg"
      w="100%"
      style={{
        borderRadius: '8px',
        border: '1px solid var(--grayscale-gray-100, #F2F4F7)',
        background: 'var(--grayscale-gray-50, #F9FAFB)',
      }}
    >
      <Grid px={{ base: 0, md: '48px' }} py={{ base: 24, md: '48px' }} gutter="xl">
        <Grid.Col span={{ base: 12, md: 6 }} order={{ base: 2, md: 1 }}>
          <Stack gap="12px" ta={{ base: 'center', md: 'left' }}>
            <Box>
              <Badge variant="default">Savings</Badge>
            </Box>
            <Text
              component="h2"
              variant="gradient"
              gradient={{ from: '#101828', to: '#667085', deg: 180 }}
              pb="6px"
              fw="bolder"
              size="f36"
              ta={{ base: 'center', md: 'left' }}
              style={{
                letterSpacing: '-1.2px',
              }}
            >
              Partnered with the industry leading technologies
            </Text>
            <Text
              size="xl"
              c="shepherdGray.5"
              ta={{ base: 'center', md: 'left' }}
              maw={640}
              fw={400}
            >
              Unique data partnerships unlock meaningful premium savings for proactive clients
            </Text>
            <Flex
              ta={{ base: 'center', md: 'left' }}
              justify={{ base: 'center', md: 'start' }}
              pt="12px"
            >
              <ShepherdGlowButton href="/solutions/savings">Explore Savings</ShepherdGlowButton>
            </Flex>
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }} order={{ base: 1, md: 2 }}>
          <Stack justify="center" h="100%">
            <SimpleGrid cols={2}>
              {IMAGES.map((item) => (
                <Box key={item.title} p={{ base: 0, md: 'md' }}>
                  <AspectRatio ratio={16 / 9} key={item.title} w="70%" m="auto">
                    <Image src={item.image} alt={item.title} style={{ objectFit: 'contain' }} />
                  </AspectRatio>
                </Box>
              ))}
            </SimpleGrid>
          </Stack>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export const RewardsSectionMarketplace = () => {
  return (
    <Container size="xl" w="100%">
      <Stack
        gap={50}
        align="center"
        px={{ base: 12, md: 48 }}
        py={{ base: 36, md: 48 }}
        style={{
          borderRadius: '8px',
          border: '1px solid var(--grayscale-gray-100, #F2F4F7)',
          background: 'var(--grayscale-gray-50, #F9FAFB)',
        }}
      >
        <SimpleGrid cols={2} w={{ base: '100%', md: '50%' }}>
          {IMAGES.map((item) => (
            <Box key={item.title} p={{ base: 'sm', md: 'md' }}>
              <AspectRatio ratio={16 / 9} key={item.title} w="70%" m="auto">
                <Image src={item.image} alt={item.title} style={{ objectFit: 'contain' }} />
              </AspectRatio>
            </Box>
          ))}
        </SimpleGrid>
        <Stack align="center">
          <Badge variant="default">Partnerships</Badge>
          <Text
            component="h2"
            variant="gradient"
            gradient={{ from: '#101828', to: '#667085', deg: 180 }}
            pb="6px"
            lh={{ base: '30px', md: '36px' }}
            fw="bolder"
            fz={{ base: '30px', md: '36px' }}
            ta="center"
            maw={800}
            style={{
              letterSpacing: '-1.2px',
            }}
          >
            Underwriting credit program for construction clients
          </Text>
          <Text
            fz={{ base: 'md', md: 'xl' }}
            c="shepherdGray.5"
            ta="center"
            maw={640}
            style={{
              letterSpacing: '-1.2px',
            }}
          >
            Shepherd’s data partnerships with leading tech companies provide automatic coverage and
            premium savings for clients.
          </Text>
        </Stack>
        <Flex ta="center" justify="center">
          <ShepherdGlowButton mailto="support@withshepherd.com">
            Become a Savings partner
          </ShepherdGlowButton>
        </Flex>
      </Stack>
    </Container>
  );
};

export default RewardsSection;
