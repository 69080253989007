'use client';
import { Box, Container, Flex, Stack, Text } from '@mantine/core';
import React from 'react';
import Image from 'next/image';

import lockton from '../../../app/images/logos/lockton.png';
import aon from '../../../app/images/logos/aon.png';
import newfront from '../../../app/images/logos/newfront.png';
import woodruff from '../../../app/images/logos/woodruff.png';
import tsib from '../../../app/images/logos/tsib.png';
import epic from '../../../app/images/logos/epic.png';
import wtw from '../../../app/images/logos/wtw.png';
import ima from '../../../app/images/logos/ima.png';
import psf from '../../../app/images/logos/psf.png';
import nfp from '../../../app/images/logos/nfp.png';
import crp from '../../../app/images/logos/crp.png';
import mcgriff from '../../../app/images/logos/mcgriff.png';
import hub from '../../../app/images/logos/hub.png';
import americanglobal from '../../../app/images/logos/americanglobal.png';
import gallagher from '../../../app/images/logos/gallagher.png';
import marsh from '../../../app/images/logos/marsh.png';
import usi from '../../../app/images/logos/usi.png';
import holmes from '../../../app/images/logos/holmes.png';

import alliant from '../../../app/images/logos/alliant.png';
import csb from '../../../app/images/logos/csb.png';
import { times } from 'lodash';
import classes from './BrokerLogos.module.css';

const IMAGES = [
  { src: nfp, alt: 'nfp' },
  { src: lockton, alt: 'lockton' },
  { src: aon, alt: 'aon' },
  { src: marsh, alt: 'marsh' },
  { src: usi, alt: 'usi' },
  { src: alliant, alt: 'alliant' },
  { src: wtw, alt: 'wtw' },
  { src: mcgriff, alt: 'mcgriff' },
  { src: americanglobal, alt: 'americanglobal' },
  { src: gallagher, alt: 'gallagher' },
  { src: crp, alt: 'crp' },
  { src: woodruff, alt: 'woodruff' },
  { src: tsib, alt: 'tsib' },
  { src: epic, alt: 'epic' },
  { src: ima, alt: 'ima' },
  { src: psf, alt: 'psf' },
  { src: newfront, alt: 'newfront' },
  { src: hub, alt: 'hub' },
  { src: csb, alt: 'csb' },
  { src: holmes, alt: 'holmes' },
];

const BrokerLogos = () => {
  return (
    <Stack gap="36px">
      <Container size="xl">
        <Text c="shepherdGray.5" ta="center" fw="bolder">
          Trusted by the leading retail brokerages in commercial risk
        </Text>
      </Container>
      <Box className={classes.container}>
        <Flex
          gap={{ base: '24px', md: '60px' }}
          wrap="nowrap"
          justify="start"
          align="center"
          px="10vw"
          className={classes.track}
        >
          {times(3, () =>
            IMAGES.map((item) => <Image key={item.alt} src={item.src} alt={item.alt} width={120} />)
          )}
        </Flex>
      </Box>
    </Stack>
  );
};

export default BrokerLogos;
