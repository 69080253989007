import React from 'react';

const IconArrowRight = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <g id="keyboard_arrow_right" opacity="0.8">
        <path
          id="Vector"
          d="M9.5 16.375L13.38 12.495L9.5 8.61498C9.11 8.22498 9.11 7.59498 9.5 7.20498C9.89 6.81498 10.52 6.81498 10.91 7.20498L15.5 11.795C15.89 12.185 15.89 12.815 15.5 13.205L10.91 17.795C10.52 18.185 9.89 18.185 9.5 17.795C9.12 17.405 9.11 16.765 9.5 16.375Z"
        />
      </g>
    </svg>
  );
};

export default IconArrowRight;
