'use client';
import React from 'react';
import { track } from '@vercel/analytics';

import classes from './ShepherdGlowButton.module.css';
import { Box, Button } from '@mantine/core';
import IconArrowRight from '../IconArrowRight';
import Link from 'next/link';

const ShepherdGlowButton = ({
  children,
  href,
  mailto,
  scrollTo,
}: {
  children: React.ReactNode;
  href?: string;
  mailto?: string;
  scrollTo?: string;
}) => {
  return (
    <Box
      className={classes.wrapper}
      style={{
        zIndex: 1,
        position: 'relative',
      }}
    >
      <Box className={classes.glow} />
      {href && (
        <Button
          variant="blackGradient"
          radius="xl"
          size="lg"
          classNames={{
            root: classes.root,
          }}
          rightSection={<IconArrowRight />}
          component={Link}
          href={href}
        >
          {children}
        </Button>
      )}
      {mailto && (
        <Button
          variant="blackGradient"
          radius="xl"
          size="lg"
          classNames={{
            root: classes.root,
          }}
          rightSection={<IconArrowRight />}
          component="a"
          href={`mailto:${mailto}`}
          onClick={() => {
            track('shepherd-glow-button clicked', {
              type: 'mailto',
              children: `${children}`,
              mailto,
            });
          }}
        >
          {children}
        </Button>
      )}
      {scrollTo && (
        <Button
          variant="blackGradient"
          radius="xl"
          size="lg"
          classNames={{
            root: classes.root,
          }}
          rightSection={<IconArrowRight />}
          onClick={(e) => {
            e.preventDefault();
            track('shepherd-glow-button clicked', {
              type: 'scrollTo',
              children: `${children}`,
            });
            const element = document.querySelector(scrollTo);

            if (element) {
              const y = element.getBoundingClientRect().top + window.scrollY + -120;

              window.scrollTo({ top: y, behavior: 'smooth' });
            } else {
              console.error(`Element ${scrollTo} not found`);
            }
          }}
        >
          {children}
        </Button>
      )}
    </Box>
  );
};

export default ShepherdGlowButton;
