import { ThemeIcon } from '@mantine/core';
import React from 'react';
import SvgDone from '../app/images/svgs/Done';

const ListItemIcon = () => {
  return (
    <ThemeIcon color="#EAECF0" radius="xl">
      <SvgDone height={16} width={16} />
    </ThemeIcon>
  );
};

export default ListItemIcon;
