'use client';
import { Container, Stack, Text, Box, List, Flex, Button, Card, AspectRatio } from '@mantine/core';
import Image from 'next/image';
import React, { Fragment } from 'react';
import SectionHeader from '../SectionHeader';

import ListItemIcon from '../ListItemIcon';
import Link from 'next/link';
import { InsuranceProductsQuery } from '../../graphql/cms/cms.generated';
import IconArrowRight from '../IconArrowRight';

const InsuranceProducts = ({ productsData }: { productsData: InsuranceProductsQuery }) => {
  return (
    <Container size="xl" pos="relative" w="100%">
      <Stack gap="96px">
        <SectionHeader
          badge="Insurance Offerings"
          title="Comprehensive coverage, from programs to projects"
          subtitle="Our products are backed by several of the top capacity and reinsurance providers in the world"
        />

        <Stack gap="24px">
          {productsData?.allInsuranceProducts.map((item) => {
            const content = (
              <Stack
                gap="24px"
                p={{ base: '0px', md: '48px' }}
                py={{ base: '16px', md: '48px' }}
                style={{
                  flex: '1 0 0',
                  alignSelf: 'stretch',
                }}
              >
                <Stack gap={8}>
                  <Text c="shepherdGray.9" fw="700" size="f36" lh="36px">
                    {item.title}
                  </Text>
                  <Text size="lg" c="shepherdGray.5">
                    {item.subtitle}
                  </Text>
                </Stack>
                {item.active && (
                  <List icon={<ListItemIcon />} spacing={8}>
                    {item.valueProps.map((point) => (
                      <List.Item key={point} c="shepherdGray.5">
                        {point}
                      </List.Item>
                    ))}
                  </List>
                )}
                {item.active && (
                  <Box>
                    <Button
                      variant="blackGradient"
                      component={Link}
                      href={`/products/${item.slug}` || ''}
                      rightSection={<IconArrowRight />}
                    >
                      Learn more about {item.title}
                    </Button>
                  </Box>
                )}
              </Stack>
            );

            return (
              <Fragment key={item.title}>
                <Flex
                  style={{ borderRadius: '8px', overflow: 'hidden' }}
                  bg="shepherdGray.1"
                  visibleFrom="md"
                  mih={360}
                >
                  <Box
                    style={{
                      flex: '1 0 0',
                      alignSelf: 'stretch',
                      overflow: 'hidden',
                    }}
                    pos="relative"
                  >
                    <Image
                      src={item.image1?.url || ''}
                      alt={item.title || ''}
                      fill
                      style={{ objectFit: 'cover' }}
                      sizes="800px"
                    />
                  </Box>
                  {content}
                </Flex>
                <Card hiddenFrom="md" padding="12px" bg="shepherdGray.1" radius="md">
                  <Card.Section>
                    <AspectRatio ratio={16 / 9}>
                      <Image src={item.image1?.url || ''} alt={item.title || ''} fill />
                    </AspectRatio>
                  </Card.Section>
                  {content}
                </Card>
              </Fragment>
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};

export default InsuranceProducts;
